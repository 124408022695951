import { Navigate } from "react-router-dom"
import axios from "axios";
import { useState } from "react";

const AuthGuard = ({ children }) => {

    let [returns, setReturn] = useState();

    const token = localStorage.getItem('jwtToken');
    axios.post('https://api.aml-tw.com/login/check', {
        token
    }).then((res) => {
        if (res.data.status) {
            setReturn(children);
        } else {
            setReturn(<Navigate to="/login" />);
        }
    }).catch((e) => {
        setReturn(<Navigate to="/login" />);
    });

    return returns;

}

export default AuthGuard;