import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AuthGuard from './middleware/AuthGuard';
import ApproveListComponent from './components/ApproveListComponent';
import LoginComponent from './components/LoginComponent';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/*' element={<AuthGuard><ApproveListComponent /></AuthGuard>} />
        <Route path="/login" element={ <LoginComponent /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
