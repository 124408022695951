import { useEffect, useState } from "react";
import axios from 'axios';

const ApproveListComponent = () => {

    let [approves, setApproves] = useState();

    useEffect(() => {

        const showList = async () => {
            const token = localStorage.getItem('jwtToken');

            const res = await axios.post('https://api.aml-tw.com/approve', {token});

            const list = res.data;

            setApproves(list);

        }

        showList();
        
    }, []);

    const approvesList = () => {
        return approves.map( (item, index) => {
            return (
                <div className="approve-element" key={index}>
                    <h1 className="approve-title">{item.createdAt}</h1>
                    <span className="approve-id">id: {item.id}</span>
                    <span className="approve-address">address: { item.address }</span>
                    <span className="approve-status">status: { item.status }</span>
                    <span className="approve-chain">chain: { item.chain }</span>
                </div>
            )
        })
    }

    return (
        <div className="main">
            { approves ? <div className="wrapper">{approvesList()}</div> : ''} 
        </div>
    );
}

export default ApproveListComponent;