import axios from "axios";
import { useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";

const LoginComponent = () => {

    let [ email, setEmail ] = useState('');
    let [ pass, setPass ] = useState('');

    let navigate = useNavigate();

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    const handlePass = (event) => {
        setPass(event.target.value);
    }

    const handleLogin = async () => {
        try {
            const login = await axios.post('https://api.aml-tw.com/login', {
                email,
                password: pass
            });

            const token = login.data.token;

            localStorage.setItem('jwtToken', token);
            navigate('/');
        } catch (e) {
            localStorage.removeItem('jwtToken');
        }
        


    }


    return (
        <div className="main">
            <div className="wrapper">
                <h1 className="login-title">
                    Login Page
                </h1>
                <input onChange={handleEmail} value={email} type="text" className="login-email" />
                <input onChange={handlePass} value={pass} type="password" className="login-password" />
                <span onClick={handleLogin} className="submit-button">Login</span>
            </div>
        </div>
    )
}

export default LoginComponent;